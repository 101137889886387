import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

const EMAIL_KEY = 'auth-email';
const USER_KEY = 'auth-user';
const LOGIN_BY_KEY = 'auth-LoginBy';
const USERID_KEY = 'auth-UserId';
const USERTYPE_KEY = 'auth-Usertype';
const TOKEN_KEY = 'auth-Token';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  isLoading = new Subject<boolean>();
  constructor() { }
  Loadershow() {
    this.isLoading.next(true);
  }
  Loaderhide() {
    this.isLoading.next(false);
  }
  signOut() {
    window.sessionStorage.clear();
  }

  public saveToken(email: string, name: string, loginby: string, userId: string, userType: string, token: string) {
    window.sessionStorage.removeItem(EMAIL_KEY);
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.removeItem(LOGIN_BY_KEY);
    window.sessionStorage.removeItem(USERID_KEY);
    window.sessionStorage.removeItem(USERTYPE_KEY);
    window.sessionStorage.removeItem(TOKEN_KEY);
    
    window.sessionStorage.setItem(EMAIL_KEY, email);
    window.sessionStorage.setItem(USER_KEY, name);
    window.sessionStorage.setItem(LOGIN_BY_KEY, loginby);
    window.sessionStorage.setItem(USERID_KEY, String(userId));
    window.sessionStorage.setItem(USERTYPE_KEY, userType);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getEmailToken(): string {
    return sessionStorage.getItem(EMAIL_KEY) || "";
  }

  public getUserToken(): string {
    return sessionStorage.getItem(USER_KEY) || "";
  }

  public getLoginByToken(): string {
    return sessionStorage.getItem(LOGIN_BY_KEY) || "";
  }
  public getUserIdToken(): number {
    return parseInt(sessionStorage.getItem(USERID_KEY) || "");
  }
  public getUsertypeToken(): string {
    return sessionStorage.getItem(USERTYPE_KEY) || "";
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY) || "";
  }

  public getTimeZone(): number {
    let dt = new Date();
    let offset = dt.getTimezoneOffset();
    offset = offset * -1;
    return offset;
  }
}
