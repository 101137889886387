import { ActionType, createAction, props } from '@ngrx/store';

export const GET_EXPORT_LOANS = 'GET_EXPORT_LOANS';
export const GET_EXPORT_LOANS_SUCCESS = 'GET_EXPORT_LOANS_SUCCESS';
export const GET_EXPORT_LOANS_FAILURE = 'GET_EXPORT_LOANS_FAILURE';

export const GetExportLoan= createAction(GET_EXPORT_LOANS, props<{ LoanId: string }>());
export const GetExportLoanSuccess = createAction(GET_EXPORT_LOANS_SUCCESS, props<{ data: File }>());
export const GetExportLoanFailure = createAction(GET_EXPORT_LOANS_FAILURE, props<{ errors?: string[], message: string }>());

export type IncomeCalculationActions =

    | ActionType<typeof GetExportLoan>
    | ActionType<typeof GetExportLoanSuccess>
    | ActionType<typeof GetExportLoanFailure>