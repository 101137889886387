// income-calculation.service.ts
import { HttpClient, HttpHandler, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import{Category} from '../../api/api-models/bankstatement/category.model';
import { LargeDepositDto,BankStatementStatusUpdateRequest, TransactionBucketUpdateDTO } from 'src/app/models/loanThresholdRequest';

@Injectable({
  providedIn: 'root'
})
export class IncomeCalculationServiceService {
  

  constructor(private http: HttpClient,@Inject('BASE_URL') private baseUrl: string) { }
    apiUrl = `${this.baseUrl}api/StatementTransaction/`;
 
  getAllIncomeDetails(loanId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}api/StatementTransaction/IncomeCalculationsDetails/${loanId}`);
  }
  getTransactionCategories(loanId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}api/StatementTransaction/TransactionCategories/${loanId}`);
  }

  updateStatementTransactionBucket(loanId: string, IsQualified: boolean, categoryCode: string): Observable<TransactionBucketUpdateDTO> {
    const url = `${this.apiUrl}StatementTransactionCategory`;
    const statement: TransactionBucketUpdateDTO = { loanId: loanId, IsQualified: IsQualified, categoryCode: categoryCode};
    return this.http.put<TransactionBucketUpdateDTO>(url, statement);
  }
  getAccountType(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/StatementSummary/GetAccountType`);
  }
  getDistinctStatementSummary(loanId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get<any>(`${this.baseUrl}api/StatementSummary/GetDistinctStatementSummaryByLoanId?loanId=${loanId}`);
  }
 
  updateStatementByAccountNumber(accountNum: string, isAccountSelected: boolean): Observable<BankStatementStatusUpdateRequest> {
    const url = `${this.baseUrl}api/StatementSummary/StatementByAccountNumber`;
    const statement: BankStatementStatusUpdateRequest = { LoanId: accountNum, isAccountSelected: isAccountSelected };
    return this.http.put<BankStatementStatusUpdateRequest>(url, statement);
  }

  getAllStatementTransactionsByLoanId(loanId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}api/StatementTransaction/StatementTransactions/${loanId}`);
  }

  updateThreshold(loanID:string,thresholdFactor:number): Observable<LargeDepositDto> {
    const url = `${this.baseUrl}api/StatementTransaction/LargeDeposit`;
    // Ensure the timerValue is included in the account object
    const thresholdRequest: LargeDepositDto = { LoanId: loanID, ThresholdValue: thresholdFactor };
    return this.http.put<LargeDepositDto>(url, thresholdRequest);
  }

  exportToExcel(loanId: string): Observable<any> {
    window.open(`${this.baseUrl}api/Loan/Export/${loanId}`, '_blank');
    return this.http.get(`${this.baseUrl}api/Loan/Export/${loanId}`);
  }

}
